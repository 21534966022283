<style lang="scss">
.digitales-portfolio {
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    .content-link-modal {
      display: flex;
      span {
        position: relative;
        top: 10px;
        display: block;
        margin-right: 5px;
      }
      &:hover {
        padding-left: 10px;
        color: $font-color;
        opacity: 0.8;
      }
    }
  }
}
.smd-modal {
  .content {
    img {
      margin: 15px auto;
      width: 100%;
      display: block;
    }
    .info-box {
      padding: 10px;
      background: #eee;
      margin: 20px 0;
    }
    .tipp-box {
      margin-bottom: 20px;
      padding: 10px;
      background: $blue;
      color: #fff;
      strong {
        text-transform: uppercase;
      }
      a {
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .rating {
    margin: 0px;
    margin-bottom: 20px;
    border-top: 2px solid $blue;
    padding-top: 20px;
    padding-bottom: 20px;
    .header {
      margin-bottom: 10px;
      @media (max-width: 768px) {
        text-align: center;
      }
      strong {
        background: $blue;
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 130%;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        @media (max-width: 992px) {
          margin-left: 0;
          display: block;
          margin-top: 5px;
        }
      }
    }
    .col-md-4 {
      text-align: center;
      border-right: 1px solid #ccc;
      @media (max-width: 992px) {
        border-right: 0;
      }
      .rating-content {
        .name {
          margin-top: 10px;
          margin-bottom: 5px;
        }
        .stars {
        }
      }
    }
    .border-right-none {
      border-right: 0;
    }
  }
}
</style>

<template>
  <div class="content digitales-portfolio container">
    <h1>Displaywerbung</h1>
    <p class="intro-txt">Die Kleine Zeitung stellt sich dem technischen Fortschritt und der Digitalisierung und geht mutig voran. So wird das digitale Produktportfolio unter Einbeziehung internationaler Trends und innovativer Ansätze laufend erweitert, evaluiert und optimiert. Im Mittelpunkt steht dabei jederzeit - Ihr individuelles Werbebedürfnis perfekt zu bedienen:</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-epaper.jpg" alt="E-Paper ZW" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Effizient im E-Paper</h2>
          <p>Sie wollen maximale Aufmerksamkeit über ein <strong>großflächiges Sujet</strong>? Erreichen Sie alle Digitalabonnenten <strong>Desktop und Mobile</strong></p>
          <ModalButton link="epaper-zw" class="content-link-modal"><span class="material-icons">arrow_right_alt</span>Alle Informationen zur beliebten E-Paper Zwischenseite</ModalButton>
        </div>
      </div>
    </div>
    <Modal link="epaper-zw" title="E-Paper Zwischenseite">
      <ul class="content-ul">
        <li>Das E-Paper der Kleinen Zeitung bringt die Printausgabe auf Tablet, Smartphone und PC – und das bereits am Vorabend ab 23:30 Uhr. Erreichen Sie mit unseren innovativen E-Paper-Werbeformen auch unsere Digital-Abonnenten.</li>
      </ul>

      <div class="tabelle-box">
        <div class="headline">
          <strong>ZWISCHENSEITE</strong><br />
          <div class="info-txt">Zwischenseiten werden auf allen Plattformen - Android-App, iOS-App und Browserversion - angezeigt.</div>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-4 text-align-left">Überregional</div>
          <div class="col-lg-4 text-align-right">1 Woche</div>
          <div class="col-lg-4 text-align-right">2 Wochen</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Gesamtausgabe</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">2.160,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">3.600,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Steiermark</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">1.440,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">2.400,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Kärnten</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">720,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">1.200,00</div>
        </div>
      </div>
      <div class="tabelle-box">
        <div class="row head-row hide-small">
          <div class="col-lg-4 text-align-left">Pro Region</div>
          <div class="col-lg-4 text-align-right">1 Woche</div>
          <div class="col-lg-4 text-align-right">2 Wochen</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Graz</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">550,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">990,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Klagenfurt</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">370,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">660,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Überregional</div>
          <div class="col-lg-4 col-6 text-align-left text-right-small">Alle anderen Regionen</div>
          <div class="col-6 text-align-left visible-small">1 Woche</div>
          <div class="col-lg-4 col-6 text-align-right">370,00</div>
          <div class="col-6 text-align-left visible-small">2 Wochen</div>
          <div class="col-lg-4 col-6 text-align-right">660,00</div>
        </div>

        <div class="info-txt-tabelle">Datenanlieferung: Seite im Hochformat (768 x 954 Px) und Querformat (1024 x 698 Px) + Vorschaubild (105 x 145 Px).</div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <img src="@/assets/img/img-content/digital/e-paper-zw.jpg" alt="E-Paper ZW" class="img-fluid" />
        </div>
      </div>
    </Modal>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-medium-rectangle.jpg" alt="Medium Rectangle" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Der Klassiker</h2>
          <p>Vorteile des MediumRectangles</p>
          <ModalButton link="medium-rectangle" class="content-link-modal"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Medium Rectangle</ModalButton>
        </div>
      </div>
    </div>
    <Modal link="medium-rectangle" title="Medium Rectangle">
      <div class="tabelle-box">
        <div class="row head-row">
          <div class="col-lg-6 col-6 text-align-left">PLATZIERUNG</div>
          <div class="col-lg-6 col-6 text-align-right">TARIF</div>
        </div>
        <div class="row content-row">
          <div class="col-lg-6 col-6 text-align-left">Auf der Startseite</div>
          <div class="col-lg-6 col-6 text-align-right">35,00*</div>
        </div>
        <div class="info-txt-tabelle">*Preisangaben in TKP (Preis für 1.000 Sichtkontakte).</div>
      </div>
      <div class="info-txt-preis">ALLE PREISE VERSTEHEN SICH IN EURO, EXKLUSIVE 20 % UST.</div>
    </Modal>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-job-tracker.jpg" alt="Job Tracker" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Finden Sie Jene, die gar nicht suchen</h2>
          <p>Vorteile des Job-Ticker <strong>supercool</strong> und überhaupt top</p>
          <ModalButton link="job-ticker" class="content-link-modal"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Job-Ticker</ModalButton>
        </div>
      </div>
    </div>
    <Modal link="job-ticker" title="Job-Ticker">
      Lorem impsum
    </Modal>

    <div class="download-pdf">
      <div class="line"><span>PDF</span></div>
      <a href="../../pdf/Ausgabe-Magazin-Kostprobe-2020.pdf" download>
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Zusammenfassung herunterladen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>434 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Displaywerbung',
  components: {
    ModalButton,
    Modal,
  },
});
</script>
