
import { defineComponent } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Displaywerbung',
  components: {
    ModalButton,
    Modal,
  },
});
